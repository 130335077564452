.body-container {
  background-color: aliceblue;
  min-height: 400vh;
  font-size: calc(7px + .75vw);
}

.split-section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
}

.picture-container {
  margin-top: 10%;
  width: 45vw;
  min-width: 300px
}

.about-me-container {
  margin-top: 5vw;
  text-align: justify;
  width: 45vw;
  min-width: 300px
}

.biography-header {
  font-weight: 600;
  margin-bottom: 5vw;
}

.biography-section-header {
  font-weight: 550;
}

.about-me-text {
  font-weight: 3;
  line-height: 2.5vh
}

.biography-picture {
  max-width: 100%;
  align-items: center;
  align-content: center;
  margin: auto;
  border-radius: 1%;
}

.skills-container {
  margin-top: 10%;
  text-align: justify;
  width: 45vw;
  min-width: 300px
}

.skills-boxes-container {
  margin-top: 15%;
  padding: 2%;
  text-align: justify;
  width: 45%;
  min-width: 300px
}

.chips {
  margin: 0.25vw;
}

.work-container {
  padding: 2%;
  text-align: justify;
}

.work-header {
  font-weight: 600;
  text-align: center;

}

.project-name {
  margin-left: 5%;
  font-weight: 550;
  margin-bottom: 0%
}

.project-company,
.project-description,
.project-timeline,
.project-tags-container {
  margin-left: 5%;
}

.cards {
  background-color: gray;
  align-items: center;
  align-content: center;
  margin: 0 auto;
  margin-bottom: 5vh;
  width: 40vw;
  min-width: 300px
}

.project-img {
  width: 90%;
  align-items: center;
  align-content: center;
  margin-left: 5%;
}

.project-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
}

.help-container {
  margin-top: 10vw;
  margin: auto;
  text-align: justify;
  width: 90vw;
  min-width: 300px
}